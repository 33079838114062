/* eslint-disable no-shadow,no-restricted-syntax,guard-for-in */
// eslint-disable-next-line guard-for-in,no-restricted-syntax
import { useEffect, useState } from 'react';

import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

export default function useFilters(schema, options) {
  const { data, refetch, onChange: onChangeFilter } = options;
  const [isLoading, setLoading] = useState(true);

  // Cache
  const [cache, setCache] = useState(() => {
    const defaultCache = {};

    for (const key in schema) {
      if (!schema[key].hasValues) {
        continue;
      }

      defaultCache[schema[key].hasValues] = [];
    }

    return defaultCache;
  });

  // Data
  const filterData = data?.carSearchFilterData ?? cache;
  const queryParams = {};
  const changeHandlers = {};
  const effectParams = [];

  for (const key in schema) {
    queryParams[key] = filterData[key];
    effectParams.push(filterData[key]);

    if (schema[key].hasValues) {
      effectParams.push(
        filterData[schema[key].hasValues],
      );
    }
  }

  useEffect(() => {
    const newCache = { ...cache };

    for (const key in schema) {
      if (!schema[key].hasValues) {
        continue;
      }

      const values = filterData[schema[key].hasValues];

      if (!values || values.length === 0) {
        continue;
      }

      newCache[schema[key].hasValues] = values;
    }

    setCache(newCache);
    setLoading(false);
  }, effectParams);

  // Handler
  for (const key in schema) {
    let handler = null;
    const { onChange } = schema[key];

    switch (schema[key].handlerType) {
      case 'event':
        handler = (e) => {
          const { value } = e.target;
          let params = {
            ...queryParams,
            [key]: value,
          };

          if (onChange) {
            const emptySchema = {};

            for (const key in schema) {
              emptySchema[key] = null;
            }

            params = onChange({
              data: params,
              emptySchema,
            });
          }

          setLoading(true);
          refetch(params);
          onChangeFilter(key, params);
        };
        break;
      case 'checkbox':
        handler = (e) => {
          const { checked } = e.target;
          let params = {
            ...queryParams,
            [key]: checked,
          };

          if (onChange) {
            const emptySchema = {};

            for (const key in schema) {
              emptySchema[key] = null;
            }

            params = onChange({
              data: params,
              emptySchema,
            });
          }

          setLoading(true);
          refetch(params);
          onChangeFilter(key, params);
        };
        break;
      case 'value':
        handler = (e, value) => {
          let params = {
            ...queryParams,
            [key]: value,
          };

          if (onChange) {
            const emptySchema = {};

            for (const key in schema) {
              emptySchema[key] = null;
            }

            params = onChange({
              data: params,
              emptySchema,
            });
          }

          setLoading(true);
          refetch(params);
          onChangeFilter(key, params);
        };
        break;
      default:
        throw new Error(
          `Handler type "${schema[key].handlerType}" not supported.`,
        );
    }

    changeHandlers[`onChange${capitalizeFirstLetter(key)}`] = handler;
  }

  return {
    isLoading,
    filterData,
    queryParams: omitBy(queryParams, isNil),
    changeHandlers,
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
