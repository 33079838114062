export const FUEL_TYPE = {
  other: 'Andere',
  gasoline: 'Benzin',
  diesel: 'Diesel',
  lpg: 'Autogas',
  naturalGas: 'Erdgas',
  electric: 'Elektro',
  hybrid: 'Hybrid',
  hydrogen: 'Wasserstoff',
  ethanol: 'Ethanol',
  hybridDiesel: 'Hybrid-Diesel',
};

export const TRANSMISSION_TYPE = {
  none: 'keine Angabe',
  manual: 'Schaltgetriebe',
  semiautomatic: 'Halbautomatik',
  automatic: 'Automatik',
};

export const AIR_CONDITIONER_TYPE = {
  none: 'keine Klimaanlage',
  airConditioner: 'Klimaanlage',
  automaticAirConditioning: 'Klimaautomatik',
};

export const POLLUTION_BADGE = {
  1: 'keine Plakette',
  2: 'rot',
  3: 'geld',
  4: 'grün',
};

export const INTERIOR_TYPE = {
  other: 'Andere',
  leather: 'Leder',
  partLeather: 'Teilleder',
  fabric: 'Stoff',
  velour: 'Velour',
  alcantara: 'Alcantara',
  fauxLeather: 'Kunstleder',
};

export const INTERIOR_COLOR = {
  other: 'Andere',
  black: 'Schwarz',
  gray: 'Grau',
  beige: 'Beige',
  brown: 'Braun',
};

export const VEHICLE_EQUIPMENT = {
  inside: {
    title: 'Innenausstattung',
    PARKING_SENSORS: 'Parksensoren',
    LEATHER_SEATS: 'Ledersitze',
    ELECTRIC_WINDOWS: 'Elektrische Fenster',
    POWER_ASSISTED_STEERING: 'Servorlenkung',
    NAVIGATION_SYSTEM: 'Navigationssystem',
    CRUISE_CONTROL: 'Tempomat',
    SUNROOF: 'Schiebedach',
    AUXILIARY_HEATING: 'Standheizung',
    ELECTRIC_HEATED_SEATS: 'Sitzheizung',
    TV: 'TV',
    AUTOMATIC_RAIN_SENSOR: 'Regensensor',
    PANORAMIC_GLASS_ROOF: 'Panoramadach',
    SPORT_SEATS: 'Sportsitze',
    CENTRAL_LOCKING: 'Zentralverrigelung',
    BLUETOOTH: 'Bluetooth',
    ON_BOARD_COMPUTER: 'Computer',
    CD_PLAYER: 'CD Player',
    HANDS_FREE_PHONE_SYSTEM: 'Freisprechanlage',
    MP3_INTERFACE: 'MP3',
    MULTIFUNCTIONAL_WHEEL: 'Multifunktionslenkrad',
    TUNER: 'Radio',
    DISABLED_ACCESSIBLE: '',
    DRIVERS_SLEEPING_COMPARTMENT: '',
    WC: 'WC',
    DIVIDER: 'Trennwand',
    DISABLED_CONVERSION: '',
    CD_MULTICHANGER: 'CD Player',
    BUNK_BED: 'Etagenbett',
    BED: 'Bett',
    MIDDLE_SEATING_ARRANGEMENT: '',
    SEP_SHOWER: '',
    SIDE_SEATING_ARRANGEMENT: '',
  },
  outside: {
    title: 'Außenausstattung',
    TRAILER_COUPLING: 'Anhängerkupplung',
    ALLOY_WHEELS: 'Leichtmetallfelgen',
    ELECTRIC_EXTERIOR_MIRRORS: 'Elektrische Seitenspiegel',
    ROOF_RAILS: '',
    SPORT_PACKAGE: 'Sportpaket',
    PROTECTION_ROOF: '',
    FULL_FAIRING: '',
    CRANE: 'Kran',
    TAIL_LIFT: '',
    SLIDINGDOOR: 'Schiebetür',
    ROOF_BARS: '',
    REAR_GARAGE: '',
    AWNING: 'Schiebedach',
    SOLAR_ENERGY_SYSTEM: '',
  },
  safety: {
    title: 'Sicherheit & Umwelt',
    ABS: 'ABS',
    ESP: 'ESP',
    EBS: 'EBS',
    PARTICULATE_FILTER_DIESEL: 'Dieselpartikelfilter',
    BIODIESEL_SUITABLE: 'Biodiesel kompatibel',
    E10: 'E10',
    IMMOBILIZER: 'Wegfahrsperre',
    XENON_HEADLIGHTS: 'Xenonlicht',
    FOUR_WHEEL_DRIVE: 'Allradantrieb',
    FRONT_FOG_LIGHTS: 'Nebelscheinwerfer',
    ISOFIX: '',
    TRACTION_CONTROL_SYSTEM: 'Traktionskontrolle',
    LIGHT_SENSOR: 'Lichtsensor',
    CATALYTIC_CONVERTER: '',
    BIODIESEL_CONVERSION: '',
    VEGETABLEOILFUEL_CONVERSION: '',
    VEGETABLEOILFUEL_SUITABLE: '',
    E10_ENABLED: 'E10',
    DISC_BRAKE: 'Scheibenbremsen',
    BENDING_LIGHTS: 'Kurvenlicht',
    DAYTIME_RUNNING_LIGHTS: 'Tagfahrlicht',
    START_STOP_SYSTEM: 'Start-Stop-System',
  },
  extras: {
    title: 'Extras',
    FULL_SERVICE_HISTORY: 'Scheckheftgepflegt',
    WARRANTY: 'Garantie',
    HU_AU_NEU: 'HU & AU neu',
    EXPORT: 'Exportfahrzeug',
    KICKSTARTER: 'Kickstarter',
    ELECTRIC_STARTER: '',
    MUNICIPAL: '',
    RETARDER: '',
    AIR_SUSPENSION: 'Luftfederung',
    FRONT_HYDRAULICS: '',
  },
};
