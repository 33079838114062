/* eslint-disable no-unused-vars */
import { FiCheckSquare, FiSearch } from 'react-icons/fi';

export const FILTER_GROUPS = [
  { id: 'basic', label: 'Basisdaten', icon: FiSearch },
  { id: 'features', label: 'Ausstattung', icon: FiCheckSquare },
];

export const PARTS_SHOP_BRAND_MAPPING = {
  HYUNDAI: {
    dealerId: 'hyundai_1897',
  },
  MITSUBISHI: {
    dealerId: 'mitsubishi_6030',
  },
};

export const FILTER_SKELETON = {
  category: {
    type: '[String]',
    hasValues: 'categories',
    handlerType: 'event',
    onChange: ({ data, emptySchema }) => ({
      ...emptySchema,
      category: data.category,
    }),
    addWhere: (where, value, params) => {
      where.category = value;
    },
  },
  dummy: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.dummy = value;
    },
  },
  brand: {
    type: 'String',
    hasValues: 'brands',
    handlerType: 'event',
    onChange: ({ data, emptySchema }) => ({
      ...emptySchema,
      category: data.category,
      brand: data.brand,
    }),
    addWhere: (where, value, params) => {
      where.brand = value;
    },
  },
  model: {
    type: 'String',
    hasValues: 'models',
    handlerType: 'event',
    onChange: ({ data, emptySchema }) => ({
      ...emptySchema,
      category: data.category,
      brand: data.brand,
      model: data.model,
    }),
    addWhere: (where, value, params) => {
      where.model = value;
    },
  },
  fuelType: {
    type: 'String',
    hasValues: 'fuelTypes',
    handlerType: 'event',
    addWhere: (where, value, params) => {
      where.fuelType = value;
    },
  },
  transmission: {
    type: 'String',
    hasValues: 'transmissions',
    handlerType: 'event',
    addWhere: (where, value, params) => {
      where.transmission = value;
    },
  },
  branch: {
    type: 'ID',
    hasValues: 'branches',
    valuesSchema: `branches {
      id
      attributes {
        name
      }
    }`,
    handlerType: 'event',
    addWhere: (where, value, params) => {
      where.branch = value;
    },
  },
  horsePower: {
    type: '[Int]',
    hasValues: 'powerRange',
    handlerType: 'value',
    addWhere: (where, value, params) => {
      where.horsePower = value;
    },
  },
  initialRegistrationYear: {
    type: '[Int]',
    hasValues: 'initialRegistrationYearRange',
    handlerType: 'value',
    onChange: ({ data, emptySchema }) => ({
      ...data,
      newCar: false,
    }),
    addWhere: (where, value, params) => {
      where.initialRegistrationYear = value;
    },
  },
  mileage: {
    type: '[Int]',
    hasValues: 'mileageRange',
    handlerType: 'value',
    addWhere: (where, value, params) => {
      where.mileage = value;
    },
  },
  price: {
    type: '[Int]',
    hasValues: 'priceRange',
    handlerType: 'value',
    addWhere: (where, value, params) => {
      where.price = value;
    },
  },
  availableNow: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.availableNow = value;
    },
  },
  saleOnly: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.saleOnly = value;
    },
  },
  newCar: {
    type: 'Boolean',
    handlerType: 'checkbox',
    onChange: ({ data, emptySchema }) => ({
      ...data,
      initialRegistrationYear: null,
    }),
    addWhere: (where, value, params) => {
      where.newCar = value;
    },
  },
  usedCar: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.usedCar = value;
    },
  },
  annualCar: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.annualCar = value;
    },
  },
  isDailyAdmission: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.isDailyAdmission = value;
    },
  },
  seductionCar: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.seductionCar = value;
    },
  },
  selectedColors: {
    type: '[String]',
    hasValues: 'colors',
    handlerType: 'value',
    addWhere: (where, value, params) => {
      where.selectedColors = value;
    },
  },
  navigationSystem: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.navigationSystem = value;
    },
  },
  parkingSensors: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.parkingSensors = value;
    },
  },
  airConditioner: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.airConditioner = value;
    },
  },
  seatHeating: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.seatHeating = value;
    },
  },
  sunroof: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.sunroof = value;
    },
  },
  cruiseControl: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.cruiseControl = value;
    },
  },
  handsFreeKit: {
    type: 'Boolean',
    handlerType: 'checkbox',
    addWhere: (where, value, params) => {
      where.handsFreeKit = value;
    },
  },
};
