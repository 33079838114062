import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export default function CarBrandTitle({ value, highlight }) {
  const theme = useTheme();
  if (!value) {
    return '';
  }

  const title = transformBrandTitle(value);

  if (!highlight) {
    return title;
  }

  return (
    <span
      style={{
        fontWeight: theme.typography.fontWeightBold,
      }}
    >
      {title}
    </span>
  );
}

CarBrandTitle.propTypes = {
  value: PropTypes.string,
  highlight: PropTypes.bool,
};

export function transformBrandTitle(value) {
  if (!value) {
    return '';
  }

  if (value === 'MG') {
    return value;
  }

  return startCase(toLower(value));
}
