// https://services.mobile.de/refdata/sites/GERMANY/classes
// https://services.mobile.de/refdata/sites/GERMANY/classes/Car/categories

export default function mapCarCategory(value) {
  if (!value || !value.includes('.')) {
    return {
      vehicleClass: 'Andere',
      vehicleCategory: 'Andere'
    };
  }

  const parts = value.split('.');
  const vehicleClass = parts[0];
  const vehicleCategory = parts[1];

  return {
    vehicleClass: mapClass(vehicleClass),
    vehicleCategory: mapCategory(vehicleCategory)
  };
}

function mapClass(value) {
  switch (value) {
    case 'AgriculturalVehicle':
      return 'Agrarfahrzeug';
    case 'Bus':
      return 'Bus';
    case 'Car':
      return 'Pkw';
    case 'ConstructionMachine':
      return 'Baumaschine';
    case 'ForkliftTruck':
      return 'Stapler';
    case 'Motorbike':
      return 'Motorrad';
    case 'Motorhome':
      return 'Wohnmobil oder -wagen';
    case 'SemiTrailer':
      return 'Auflieger';
    case 'SemiTrailerTruck':
      return 'Sattelzugmaschine (SZM)';
    case 'Trailer':
      return 'Anhänger';
    case 'TruckOver7500':
      return 'Lkw über 7,5 t';
    case 'VanUpTo7500':
      return 'Transporter oder Lkw bis 7,5 t';
    default:
      return 'Andere';
  }
}

function mapCategory(value) {
  switch (value) {
    case 'Cabrio':
      return 'Cabrio/Roadster';
    case 'EstateCar':
      return 'Kombi';
    case 'Limousine':
      return 'Limousine';
    case 'OffRoad':
      return 'SUV';
    case 'SmallCar':
      return 'Kleinwagen';
    case 'SportsCar':
      return 'Sportwagen/Coupé';
    case 'Van':
      return 'Van/Kleinbus';
    default:
      return 'Andere';
  }
}
