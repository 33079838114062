import React from 'react';
import { FiPercent } from 'react-icons/fi';
import { styled } from '@mui/material/styles';

const SIZE = 32;

const Burst = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: SIZE,
  height: SIZE,
  margin: theme.spacing(1),
  position: 'relative',
  textAlign: 'center',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: SIZE,
    width: SIZE,
    backgroundColor: theme.palette.primary.main,
  },
  '&::before': {
    transform: 'rotate(30deg)',
  },
  '&::after': {
    transform: 'rotate(60deg)',
  },
}));

const Icon = styled(FiPercent)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.primary.contrastText,
  zIndex: 1,
  fontSize: SIZE * 0.65,
}));

export default function SaleBadge() {
  return (
    <Burst>
      <Icon />
    </Burst>
  );
}
