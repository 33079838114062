import PropTypes from 'prop-types';

export default function PageContainer(props) {
  const { children } = props;
  return children;
}

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
